export const initSpeculationRules = () => {
    if (HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules')) {
        const specScript = document.createElement('script')
        specScript.type = 'speculationrules'
        const specRules = {
            prerender: [
                {
                    where: {
                        and: [{ href_matches: '/*' }, { not: { selector_matches: '.do-not-prerender' } }],
                    },
                    eagerness: 'moderate',
                },
            ],
        }
        specScript.textContent = JSON.stringify(specRules)
        document.body.append(specScript)
    }
}
