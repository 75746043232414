import Alpine from 'alpinejs'

export const initCollapsible = () => {
    Alpine.data('collapsible', (minHeight = 0, expandedState = false) => ({
        contentHeight: minHeight ? minHeight : 0,
        active: expandedState,
        init() {
            setTimeout(() => {
                if (expandedState) {
                    this.contentHeight = this.$refs.content.scrollHeight
                }
            }, 300)

            window.addEventListener(
                'resize',
                this.debounce(() => this.updateContentHeight())
            )
        },
        toggle() {
            this.active = !this.active
            this.contentHeight = this.active ? this.$refs.content.scrollHeight : minHeight
        },
        updateContentHeight() {
            if (this.active) {
                this.contentHeight = this.$refs.content.scrollHeight
            }
        },
        debounce(func, timeout = 250) {
            let timer
            return (...args) => {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    func.apply(this, args)
                }, timeout)
            }
        },
    }))
}
