import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initSlider = () => {
    Alpine.data('initImageSlider', () => ({
        init() {
            var splide = new Splide(this.$el, {
                isNavigation: false,
                arrows: false,
                pagination: false,
                perPage: 4,
                gap: 30,
                breakpoints: {
                    475: {
                        perPage: 1,
                    },
                    640: {
                        perPage: 2,
                    },
                    900: {
                        perPage: 2,
                    },
                    1024: {
                        perPage: 3,
                    },
                },
            })

            var bar = this.$refs.progressBar

            splide.on('mounted move', function () {
                var end = splide.Components.Controller.getEnd() + 1
                var rate = Math.min((splide.index + 1) / end, 1)
                bar.style.width = String(100 * rate) + '%'
            })

            splide.mount()
        },
    }))

    Alpine.data('initNewsSlider', () => ({
        init() {
            var newsSlider = new Splide(this.$el, {
                isNavigation: false,
                arrows: false,
                pagination: false,
                perPage: 4,
                gap: 30,
                mediaQuery: 'min',
                breakpoints: {
                    0: {
                        perPage: 1,
                    },
                    640: {
                        perPage: 2,
                    },
                    900: {
                        perPage: 3,
                    },
                    1024: {
                        perPage: 4,
                    },
                    1200: {
                        destroy: true,
                        perPage: 3,
                    },
                },
            })

            var bar = this.$refs.newsProgressBar

            const ProgressBar = (Splide) => {
                function progressBar() {
                    var end = newsSlider.Components.Controller.getEnd() + 1
                    var rate = Math.min((newsSlider.index + 1) / end, 1)
                    bar.style.width = String(100 * rate) + '%'
                }

                return {
                    mount() {
                        Splide.on('mounted move resize', progressBar)
                    },
                }
            }

            newsSlider.mount({ ProgressBar })
        },
    }))
}
